import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { notNull } from '@remento/utils/array/notNull';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { createNotificationSettingsEditForm } from '@/modules/account-settings/forms/notification-settings-edit.form';
import { getFormValue, isFormDirty, isFormValid } from '@/modules/form/form';
import { getProjectSettingsPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useProjectsQuery } from '@/services/api/project';
import { useNotificationSettingUserQuery, usePersonUserQuery } from '@/services/api/user/user.service.hook';
import { captureException } from '@/utils/captureException';

import {
  NotificationFormItem,
  NotificationSettingsEdit,
} from '../components/NotificationSettingsEdit/NotificationSettingsEdit.js';

export interface NotificationsSettingsEditContainerProps {
  open: boolean;
  projectId: string | null;
  personId?: string | null;
  onClose: () => void;
}

const initialNotificationFormItem: NotificationFormItem[] = [
  {
    label: 'New recording shared',
    description: 'Sent after a new recording is submitted.',
    path: 'storyRecorded',
    order: 4,
  },
  {
    label: 'Reminder to react',
    description: 'Sent after watching a story without reacting',
    path: 'reminderToReact',
    hidePhone: true, // Enable on P2
    order: 5,
  },
  {
    label: 'Reaction received',
    description: 'Sent after a new reaction to a story is shared.',
    path: 'reactionSent',
    order: 6,
  },
  {
    label: 'New highlight reel',
    description: 'Sent after a new reaction to a story is shared.',
    path: 'storyRecordedAIHighlightReel',
    order: 7,
  },
  {
    label: 'New poll',
    description: 'Sent after a new prompt poll is created.',
    path: 'pollCreated',
    hidePhone: true, // Enable on P2
    order: 8,
  },
];

export function NotificationsSettingsEditContainer({
  open,
  projectId,
  personId,
  onClose,
}: NotificationsSettingsEditContainerProps) {
  const { entityCacheManagerService, userService, projectCacheService } = useServices();

  const personQuery = usePersonQuery(open ? personId : null);
  const userQuery = usePersonUserQuery(open ? personId : null);
  const userNotificationQuery = useNotificationSettingUserQuery(open ? (userQuery.data?.refIds ?? null) : null);
  const projectsQuery = useProjectsQuery();

  const user = useUser();
  const isOwner = user != null ? personQuery.data?.refIds.includes(user.personId) : false;

  const noPhone = userQuery.data?.communicationChannels.phone == null;

  const form = useMemo(() => {
    return createNotificationSettingsEditForm(userNotificationQuery.data);
  }, [userNotificationQuery.data]);

  const [notificationFormItems, setNotificationFormItems] =
    useState<NotificationFormItem[]>(initialNotificationFormItem);

  // Enable notification settings based on criterias
  useEffect(() => {
    (async () => {
      const items: NotificationFormItem[] = [...initialNotificationFormItem];

      if (!projectsQuery.data || !personId) {
        setNotificationFormItems(items);
        return;
      }

      // Load user projects
      const projects = (
        await Promise.all(projectsQuery.data?.map((projectId) => projectCacheService.getProject(projectId)))
      ).filter(notNull);

      // Display for any user who is an owner of a project with timePeriod === 'PAST'
      const isOwnerOfPastPeriodProject = projects.some(
        ({ ownerPersonId, configuration }) => ownerPersonId === personId && configuration.timePeriod === 'PAST',
      );
      if (isOwnerOfPastPeriodProject) {
        items.push({
          label: 'Reminder to add prompts',
          description: 'Sent when it’s time to add more prompts to a storyteller’s queue.',
          path: 'reminderToAddPrompt',
          order: 0,
        });
      }

      // Display for any user who is a recipient of one of their project
      const isRecipientOfNonPresentPeriodProject = projects.some(({ notifications }) =>
        notifications.recipientPersonIds.includes(personId),
      );
      if (isRecipientOfNonPresentPeriodProject) {
        items.push({
          label: 'Time to record',
          description: 'Sent to you if you are the storyteller and do not have a prompt queued up.',
          path: 'timeToRecord',
          order: 1,
        });

        items.push({
          label: 'Reminders to record',
          description: 'Sent when it’s time to record a new story.',
          path: 'reminderToRecord',
          order: 2,
          extraDescription:
            projectId == null ? null : (
              <RMText type="sans" size="s" color="on-surface-secondary" italic>
                Looking to change timing or frequency of prompts?{' '}
                <Link to={getProjectSettingsPath(projectId, { openPromptSettings: true })}>
                  <RMText type="sans" size="s" color="on-surface-secondary" italic underline>
                    Go to prompt settings
                  </RMText>
                </Link>
                .
              </RMText>
            ),
        });
      }

      // Display for any user who is an owner of a project which has at least one recipient who is not them.
      const isOwnerAndOtherRecipients = projects.some(
        ({ ownerPersonId, notifications }) =>
          ownerPersonId === personId &&
          notifications.recipientPersonIds.some((recipientPersonId) => recipientPersonId !== personId),
      );
      if (isOwnerAndOtherRecipients) {
        items.push({
          label: 'Reminders to nudge storyteller',
          description: 'Sent if your storyteller hasn’t responded to a sent prompt.',
          path: 'reminderToNudge',
          hidePhone: true,
          order: 3,
        });
      }

      // Sort to the correct order
      setNotificationFormItems(items.sort((a, b) => a.order - b.order));
    })();
  }, [personId, projectCacheService, projectId, projectsQuery.data]);

  const handleSave = useCallback(async () => {
    const formValues = getFormValue(form);
    if (!isFormValid(form) || formValues == null) {
      return;
    }

    try {
      if (!userNotificationQuery.data) {
        logger.warn('USER-NOTIFICATION-SETTINGS-NOT-LOADED-YET');
        return;
      }

      const userNotificationSettings = userNotificationQuery.data;
      const mutations: EntityMutation[] = [];

      mutations.push(...userService.createSetNotificationSettingsMutation(userNotificationSettings, formValues));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      onClose();
      toast('Notifications settings updated');
    } catch (error) {
      captureException(error, true);
      toast('Failed to update notifications settings', 'dialog-panel-toast', 'error');
    }
  }, [form, userNotificationQuery.data, userService, entityCacheManagerService, onClose]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    onClose();
  }, [onClose]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    onClose();
  }, [form, onClose]);

  return (
    <RMDialogPanel.Root open={open} onClose={handleClose}>
      <RMDialogPanel.Title onClose={handleClose}>
        {isOwner ? 'My' : 'Storyteller'} notification settings
      </RMDialogPanel.Title>
      <RMDialogPanel.Content style={{ paddingTop: 0 }}>
        {userNotificationQuery.data ? (
          <NotificationSettingsEdit form={form} notificationFormItems={notificationFormItems} noPhone={noPhone} />
        ) : (
          <RMDialogPanel.Loader />
        )}
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" autoLoading fullWidth onClick={handleSave}>
          Save
        </RMButton>
      </RMDialogPanel.Actions>

      <RMConfirmationModal
        open={closeConfirmationOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSaveChanges}
        onCancel={handleDiscardChanges}
      />
    </RMDialogPanel.Root>
  );
}
